import React from 'react';

const styles = {
  containerAbout: {
    backgroundColor: '#2c3e50',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    fontSize: '1.4rem',
    padding: '5vw',
    width: '100%',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      padding: '2vw',
    },
  },
  containerAboutAndTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  containerAboutInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    maxWidth: '1200px', // Limita el ancho máximo del contenido
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: '1vw', // Ajusta el espacio entre la imagen y el texto en móviles
      textAlign: 'center',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2vw', // Espacio entre las imágenes
    width: '100%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: '1vw',
    },
  },
  aboutImage: {
    width: '20vw', // Usa vw para el tamaño dinámico de la imagen
    height: '20vw', // Asegura que las imágenes tengan la misma altura
    objectFit: 'cover', // Mantiene la proporción y cubre el contenedor
    borderRadius: '10px', // Opcional: añade bordes redondeados
    '@media (max-width: 768px)': {
      width: '90vw', // Ajusta el tamaño de la imagen en móviles
      height: 'auto', // Ajusta la altura en móviles para mantener la proporción
    },
  },
  aboutText: {
    fontSize: '1.1rem',
    maxWidth: '70vw',
    '@media (max-width: 768px)': {
      fontSize: '1rem', // Ajusta el tamaño de la fuente en móviles
      maxWidth: '90vw', // Ajusta el ancho máximo del texto en móviles
    },
  },
  pdfButton: {
    display: 'inline-block',
    padding: '1vw 2vw',
    fontSize: '1rem',
    color: '#ffffff',
    backgroundColor: '#3498db',
    borderRadius: '1vw',
    textDecoration: 'none',
    textAlign: 'center',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    margin: '1vw',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem', // Ajusta el tamaño de la fuente en móviles
      padding: '0.8vw 1.5vw', // Ajusta el padding en móviles
    },
  },
  pdfButtonHover: {
    backgroundColor: '#2980b9',
    transform: 'scale(1.05)',
  },
  pdfButtonActive: {
    backgroundColor: '#2471a3',
    transform: 'scale(0.98)',
  },
};

function About() {
  return (
    <div style={styles.containerAbout}>
      <div style={styles.containerAboutAndTitle}>
        <h2>Quiénes Somos</h2>
        <section id="section-about" style={styles.containerAboutInfo}>
          <div style={styles.imageContainer}>
            <img src="Logo.png" alt="FBS Logo" style={styles.aboutImage} />
          </div>
          <p style={styles.aboutText}>
            En <strong>FBS</strong>, comenzamos nuestra trayectoria ofreciendo detergentes de alta calidad, dedicados a la limpieza efectiva y ecológica. Con el tiempo, hemos expandido nuestra gama de productos para incluir una variedad de soluciones de aseo, manteniendo siempre nuestro compromiso con la excelencia y la satisfacción del cliente.
          </p>
          <div>
            <h3>Descarga nuestras fichas técnicas:</h3>
            <a
              href="/FBS1.pdf"
              style={styles.pdfButton}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.pdfButtonHover.backgroundColor}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.pdfButton.backgroundColor}
              onMouseDown={(e) => {
                e.currentTarget.style.backgroundColor = styles.pdfButtonActive.backgroundColor;
                e.currentTarget.style.transform = styles.pdfButtonActive.transform;
              }}
              onMouseUp={(e) => {
                e.currentTarget.style.backgroundColor = styles.pdfButtonHover.backgroundColor;
                e.currentTarget.style.transform = styles.pdfButtonHover.transform;
              }}
            >
              Ficha Técnica FBS
            </a>
            <a
              href="/FBS2.pdf"
              style={styles.pdfButton}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.pdfButtonHover.backgroundColor}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.pdfButton.backgroundColor}
              onMouseDown={(e) => {
                e.currentTarget.style.backgroundColor = styles.pdfButtonActive.backgroundColor;
                e.currentTarget.style.transform = styles.pdfButtonActive.transform;
              }}
              onMouseUp={(e) => {
                e.currentTarget.style.backgroundColor = styles.pdfButtonHover.backgroundColor;
                e.currentTarget.style.transform = styles.pdfButtonHover.transform;
              }}
            >
              Hoja de seguridad FBS
            </a>
          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
