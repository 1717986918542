import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const styles = {
  containerWelcome: {
    
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    boxSizing: 'border-box',
    overflow: 'hidden',
    position: 'relative',
    
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textOverlay: (fontSize) => ({
    position: 'absolute',
    color: '#ffffff',
    fontSize: fontSize,
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
  }),
};

function Welcome() {
  const [fontSize, setFontSize] = useState('4rem');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setFontSize('2.5rem'); // Tamaño de letra más pequeño para móviles
      } else {
        setFontSize('4rem'); // Tamaño de letra para pantallas grandes
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Ejecutar inicialmente para establecer el tamaño correcto

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section id="section-welcome" style={styles.containerWelcome}>
      <img src="background.jpg" alt="Background" style={styles.image} />
      <motion.div
        style={styles.textOverlay(fontSize)}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
      >
        Bienvenidos a FBS
      </motion.div>
    </section>
  );
}

export default Welcome;
