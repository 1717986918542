import React from 'react';

const styles = {
  containerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '4vw', // Ajusta el padding dinámicamente
    background: 'linear-gradient(to bottom, #2c3e50, #1a252f)', // Gradiente lineal oscuro
    color: '#ffffff',
    width: '100%',
    boxSizing: 'border-box',
    
  },
};

function Footer() {
  return (
    <footer style={styles.containerFooter}>
      <p>© 2024 FBS. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;
