import React, { useState } from 'react';
import { motion } from 'framer-motion';
import productImages from '../productImages.json'; 
import { FaTimes, FaChevronUp } from 'react-icons/fa';

const styles = {
  containerProducts: {
    
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5vw',
    width: '100%',
    color: '#ffffff',
    boxSizing: 'border-box',
    paddingLeft: '15vw',
    paddingRight: '15vw',
    
  },
  productsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: '2vw',
    width: '100%',
    boxSizing: 'border-box',
    marginLeft: '10px',
    marginRight: '10px',
  },
  productItem: {
    backgroundColor: '#f9f9f9',
    padding: '1vw',
    textAlign: 'center',
    borderRadius: '1vw',
    border: '1px solid #ddd',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  productItemHover: {
    transform: 'scale(1.05)',
    boxShadow: '0 0.5vw 1vw rgba(0, 0, 0, 0.2)',
  },
  productImage: {
    width: '100%',
    height: 'auto',
    maxHeight: '30vh',
    objectFit: 'contain',
    borderRadius: '1vw',
    marginBottom: '1vw',
  },
  colorOptions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1vw',
  },
  colorOption: {
    width: '3.5vh',
    height: '3.5vh',
    borderRadius: '50%',
    margin: '0 1vw',
    cursor: 'pointer',
    border: '2px solid transparent',
  },
  selectedColor: {
    border: '2px solid #000',
  },
  pink: {
    backgroundColor: '#ff69b4',
  },
  blue: {
    backgroundColor: '#3498db',
  },
  green: {
    backgroundColor: '#2ecc71',
  },
  productLabel: {
    color: '#333',
    fontSize: '1.2rem',
    marginTop: '1vw',
  },
  prices: {
    marginTop: '1vw',
    textAlign: 'left',
    color: '#333',
    position: 'relative',
  },
  priceTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '1vw',
  },
  priceTableHead: {
    backgroundColor: '#f0f0f0',
    color: '#333',
  },
  priceTableCell: {
    padding: '0.5vw',
    border: '1px solid #ddd',
  },
  priceSeparator: {
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    margin: '1vw 0',
  },
  viewMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1vw',
    cursor: 'pointer',
  },
  iconExpand: {
    fontSize: '1.5rem',
    color: '#333',
    marginRight: '0.5vw',
  },
  viewMoreText: {
    fontSize: '1rem',
    color: '#333',
  },
  expandedProductItem: {
    position: 'relative',
    zIndex: 10,
    transform: 'scale(1.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    boxShadow: '0 1vw 2vw rgba(0, 0, 0, 0.3)',
  },
  blurredBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    zIndex: 5,
  },
  closeButton: {
    position: 'absolute',
    top: '1vw',
    right: '1vw',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '2rem',
    color: '#333',
  },
};
function Products() {
  const productList = Object.keys(productImages);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedColors, setSelectedColors] = useState(Array(productList.length).fill(''));
  const [selectedId, setSelectedId] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleColorSelect = (index, color) => {
    const updatedColors = [...selectedColors];
    updatedColors[index] = color;
    setSelectedColors(updatedColors);
    console.log(`Color seleccionado para ${productList[index]}:`, color);
  };

  const handleClickOutside = () => {
    setSelectedId(null); 
  };

  const handleCardClick = (product) => {
    if (selectedId === product) return;
    setSelectedId(product); 
  };

  const handleClose = (e) => {
    e.stopPropagation(); 
    setSelectedId(null); 
  };

  return (
    <section id="section-products" style={styles.containerProducts}>
      <h2>Productos</h2>
      <div style={styles.productsGrid}>
        {productList.map((product, index) => {
          const availableColors = Object.keys(productImages[product]);
          const selectedColor = selectedColors[index] || availableColors[0];
          const productDetails = productImages[product][selectedColor] || {};
          const isSelected = selectedId === product;

          return (
            <div
              key={index}
              style={{
                ...styles.productItem,
                ...(hoveredIndex === index && styles.productItemHover),
                ...(isSelected ? styles.expandedProductItem : {}),
              }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => !isSelected && handleCardClick(product)} 
            >
              <img
                src={productDetails.image}
                alt={`${product}`}
                style={styles.productImage}
              />
              <div style={styles.colorOptions}>
                {availableColors.map((color) => (
                  <div
                    key={color}
                    style={{
                      ...styles.colorOption,
                      ...styles[color],
                      ...(selectedColor === color && styles.selectedColor),
                    }}
                    onClick={() => handleColorSelect(index, color)}
                  />
                ))}
              </div>
              <div style={styles.productLabel}>{product}</div>
              {isSelected && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{ marginTop: '1vw' }}
                >
                  {/* Litros del producto */}
                  <div style={{ marginBottom: '1vw', color: '#333', fontSize: '1.2rem' }}>
                    <strong>Litros: </strong> {productDetails.liters} L
                  </div>

                  {/* Tabla de precios */}
                  <div style={styles.prices}>
                    <table style={styles.priceTable}>
                      <thead style={styles.priceTableHead}>
                        <tr>
                          <th style={styles.priceTableCell}>Tipo</th>
                          <th style={styles.priceTableCell}>Precio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={styles.priceTableCell}>Unidad</td>
                          <td style={styles.priceTableCell}>${productDetails.prices.unit || '-'}</td>
                        </tr>
                        <tr>
                          <td style={styles.priceTableCell}>Al por mayor</td>
                          <td style={styles.priceTableCell}>${productDetails.prices.wholesale || '-'}</td>
                        </tr>
                        <tr>
                          <td style={styles.priceTableCell}>Palet</td>
                          <td style={styles.priceTableCell}>${productDetails.prices.palet || '-'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p style={{ color: '#000' }}>{productDetails.description}</p>
                  <button
                    onClick={handleClose}
                    style={styles.closeButton}
                  >
                    <FaTimes />
                  </button>
                  <div
                    style={styles.viewMoreContainer}
                    onClick={handleClose}
                  >
                    <FaChevronUp style={styles.iconExpand} />
                    <span style={styles.viewMoreText}>Ver menos</span>
                  </div>
                </motion.div>
              )}
            </div>
          );
        })}
      </div>

      {selectedId && (
        <div style={styles.blurredBackground} onClick={handleClickOutside} />
      )}
    </section>
  );
}

export default Products;
