import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const navItems = [
  { text: 'Bienvenidos', id: 'section-welcome' },
  { text: 'Productos', id: 'section-products' },
  { text: 'Quienes somos', id: 'section-about' },
  { text: 'Contáctenos', id: 'section-contact' },
];

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

function Header() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleNavClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false); // Cerrar el menú en dispositivos móviles después de hacer clic
    }
  };

  const styles = {
    headerFixed: {
      position: 'fixed',
      top: 0,
      width: '100%',
      background: '#2c3e50',
      padding: '1rem',
      boxSizing: 'border-box',
      zIndex: 1000,
      boxShadow: '0 14px 16px rgba(0, 0, 0, 0.1)',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
    },
    headerLogo: {
      display: 'flex',
      alignItems: 'center',
    },
    logoImage: {
      width: isMobile ? '30vw' : '8vw',
      height: 'auto',
      paddingLeft: isMobile ? '0.5rem' : '1rem',
    },
    menuIcon: {
      display: isMobile ? 'block' : 'none',
      fontSize: '2.2rem',
      color: '#f0f0f0',
      cursor: 'pointer',
    },
    headerNav: {
      display: isMobile ? (isMenuOpen ? 'flex' : 'none') : 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'center',
      marginTop: isMobile ? '1rem' : '0',
      width: isMobile ? '100%' : 'auto',
    },
    navList: {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      margin: 0,
      flexDirection: isMobile ? 'column' : 'row',
      width: isMobile ? '100%' : 'auto',
    },
    navItem: {
      padding: isMobile ? '1rem' : '0.5rem 1rem',
      textAlign: 'center',
    },
    navLink: (isHovered) => ({
      textDecoration: 'none',
      fontSize: isHovered ? '1.6rem' : '1.4rem',
      color: isHovered ? '#FFC107' : '#f0f0f0',
      fontWeight: 500,
      transition: 'color 0.3s ease, font-size 0.3s ease',
      cursor: 'pointer',
    }),
  };

  return (
    <header style={styles.headerFixed}>
      <div style={styles.headerContainer}>
        <div style={styles.headerLogo}>
          <img src="Fbs.png" alt="FBS Logo" style={styles.logoImage} />
        </div>
        <div style={styles.menuIcon} onClick={handleMenuToggle}>
          <i className="fas fa-bars"></i> {/* Ícono de menú */}
        </div>
        {!isMobile && (
          <div style={styles.headerNav}>
            <ul style={styles.navList}>
              {navItems.map((item, index) => (
                <li
                  key={item.text}
                  style={styles.navItem}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleNavClick(item.id)}
                >
                  <span
                    style={styles.navLink(index === hoveredItem)}
                  >
                    {item.text}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {isMobile && isMenuOpen && (
        <div style={styles.headerNav}>
          <ul style={styles.navList}>
            {navItems.map((item, index) => (
              <li
                key={item.text}
                style={styles.navItem}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleNavClick(item.id)}
              >
                <span
                  style={styles.navLink(index === hoveredItem)}
                >
                  {item.text}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;
