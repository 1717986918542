import React from 'react';
import Header from './components/Header';
import Welcome from './components/Welcome';
import Products from './components/Products';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { HashRouter as Router } from 'react-router-dom';

const styles = {
  containerPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    overflowX: 'hidden',
    backgroundColor: '#2c3e50',
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    margin: 0,
    overflowY: 'auto', // Permite el desplazamiento vertical si el contenido es mayor que la altura de la ventana
  },
};

function App() {
  return (
    <Router>
      <div style={styles.containerPage}>
        <Header />
        <div style={styles.containerMain}>
          <Welcome />
          <Products />
          <About />
          <Contact />
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
