import React from 'react';
import { motion } from 'framer-motion';

const styles = {
  containerContact: {
    
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5vw',
    width: '70vw',
    color: '#fff',
    boxSizing: 'border-box',
  },
  whatsappContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ddd',
    borderRadius: '1vw',
    padding: '4vw',
    boxShadow: '0 0.5vw 1vw rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '70vw',
  },
  whatsappLogo: {
    width: '10vw',
    height: 'auto',
    marginBottom: '2vw',
  },
  whatsappButton: {
    display: 'inline-block',
    padding: '2vw 4vw',
    fontSize: '1.2rem',
    color: '#ffffff',
    backgroundColor: '#25D366',
    borderRadius: '1.5vw',
    textDecoration: 'none',
    textAlign: 'center',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  },
  whatsappButtonHover: {
    backgroundColor: '#1ebc57',
    transform: 'scale(1.05)',
  },
  whatsappButtonActive: {
    backgroundColor: '#239D53',
    transform: 'scale(0.98)',
  },
};

function Contact() {
  return (
    <section id="section-contact" style={styles.containerContact}>
      <h2>Contáctenos</h2>
      <div style={styles.whatsappContainer}>
        <a href="https://wa.me/56976840528">
        <motion.img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
          alt="WhatsApp Logo"
          style={styles.whatsappLogo}
          animate={{ y: [0, -15, 0] }} // Sube y baja
          transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }} // Animación continua
        />
        </a>
        <a
          href="https://wa.me/56976840528"
          style={styles.whatsappButton}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.whatsappButtonHover.backgroundColor}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.whatsappButton.backgroundColor}
          onMouseDown={(e) => {
            e.currentTarget.style.backgroundColor = styles.whatsappButtonActive.backgroundColor;
            e.currentTarget.style.transform = styles.whatsappButtonActive.transform;
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.backgroundColor = styles.whatsappButtonHover.backgroundColor;
            e.currentTarget.style.transform = styles.whatsappButtonHover.transform;
          }}
        >
          ¡Contáctanos en WhatsApp!
        </a>
      </div>
    </section>
  );
}

export default Contact;
